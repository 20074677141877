@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-Light.eot");
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url("/assets/fonts/Montserrat-Light.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Montserrat-Light.woff") format("woff"),
    url("/assets/fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-MediumItalic.eot");
  src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"),
    url("/assets/fonts/Montserrat-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat-MediumItalic.woff") format("woff"),
    url("/assets/fonts/Montserrat-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-BoldItalic.eot");
  src: local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"),
    url("/assets/fonts/Montserrat-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat-BoldItalic.woff") format("woff"),
    url("/assets/fonts/Montserrat-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-SemiBold.eot");
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url("/assets/fonts/Montserrat-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat-SemiBold.woff") format("woff"),
    url("/assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-ExtraBoldItalic.eot");
  src: local("Montserrat ExtraBold Italic"), local("Montserrat-ExtraBoldItalic"),
    url("/assets/fonts/Montserrat-ExtraBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat-ExtraBoldItalic.woff") format("woff"),
    url("/assets/fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-Italic.eot");
  src: local("Montserrat Italic"), local("Montserrat-Italic"),
    url("/assets/fonts/Montserrat-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat-Italic.woff") format("woff"),
    url("/assets/fonts/Montserrat-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-Bold.eot");
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url("/assets/fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Montserrat-Bold.woff") format("woff"),
    url("/assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-LightItalic.eot");
  src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"),
    url("/assets/fonts/Montserrat-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat-LightItalic.woff") format("woff"),
    url("/assets/fonts/Montserrat-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-BlackItalic.eot");
  src: local("Montserrat Black Italic"), local("Montserrat-BlackItalic"),
    url("/assets/fonts/Montserrat-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat-BlackItalic.woff") format("woff"),
    url("/assets/fonts/Montserrat-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-SemiBoldItalic.eot");
  src: local("Montserrat SemiBold Italic"), local("Montserrat-SemiBoldItalic"),
    url("/assets/fonts/Montserrat-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat-SemiBoldItalic.woff") format("woff"),
    url("/assets/fonts/Montserrat-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-Regular.eot");
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("/assets/fonts/Montserrat-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat-Regular.woff") format("woff"),
    url("/assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-Medium.eot");
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("/assets/fonts/Montserrat-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat-Medium.woff") format("woff"),
    url("/assets/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-ExtraBold.eot");
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"),
    url("/assets/fonts/Montserrat-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat-ExtraBold.woff") format("woff"),
    url("/assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-Black.eot");
  src: local("Montserrat Black"), local("Montserrat-Black"),
    url("/assets/fonts/Montserrat-Black.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Montserrat-Black.woff") format("woff"),
    url("/assets/fonts/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
