.App {
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

* {
  font-family: Montserrat, sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1e1e1e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.block {
  display: flex;
  flex-direction: column;
  border: 2px solid #e64940;
  border-radius: 25px;
  padding: 10px 20px;
  margin-bottom: 30px;
  /* min-height: 564px; */
  margin-top: 30px;
  min-width: 300px;
  width: 100%;
  max-width: 500px;
  align-items: center;
  justify-content: space-around;
  max-height: 400px;
  min-height: 200px;
  margin: 10px;
}

.title {
  font-weight: 500;
  font-size: 28px;
  line-height: 130%;
  margin-bottom: 20px;
  color: white;
  display: flex;
  justify-content: center;
  position: relative;
}

.actions {
  display: flex;
  width: 100%;
  gap: 12px;
  justify-content: space-around;
  flex-wrap: wrap;
}

.btn1 {
  border-radius: 25px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #000;
  border: 1px solid #e54557;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  padding: 12px 30px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 500;
  background: transparent;
  color: white;
  width: 200px;
}

.btn2 {
  border-radius: 25px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #000;
  border: 1px solid #e54557;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  padding: 12px 30px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 500;
  border-width: 1px;
  color: #fff;
  border-radius: 25px;
  letter-spacing: 0.5px;
  background: linear-gradient(90deg, #ee6647 0.25%, #df3260);
  background-size: 200% 200%;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: black;
  width: 200px;
}

.btn2:hover {
  background-position: 35% 10%;
  background: linear-gradient(288deg, #ee6647 50%, #df3260);
}
.btn1:hover {
  background-position: 35% 10%;
  background: linear-gradient(288deg, #ee6647 50%, #df3260);
}

.btn1,
.btn2 {
  min-width: 175px;
  width: 100%;
  flex: 1;
  text-decoration: none;
}
