@import "../variables.scss";
@import "../mixins.scss";

.checkbox {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 8px;
}

.square {
  display: inline-flex;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 3px solid $colors-brand-color-2;
}

.checkbox_checked .square {
  background-color: $colors-brand-color-2;
  background-image: url("/assets/icons/check.svg");
  background-repeat: no-repeat;
  background-position: center center;
}
