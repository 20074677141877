@import "../variables.scss";
@import "../mixins.scss";

.buttons {
  display: flex;
  justify-content: space-between;
  &__center{
    justify-content: center;
  }
}

.items {
  display: flex;
  width: 100%;
  margin: 74px auto 40px auto;
  gap: 20px;
  max-width: 590px;

  @include respond-below(md) {
    @include hide-scroll;
    overflow: auto;
    // margin: 58px -20px 32px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.link{
  text-decoration: none;
}