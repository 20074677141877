@import "./variables";
@import "./mixins";

.form {
  margin-bottom: 64px;
}

.container {
  @include respond-above(xl) {
    padding: 0 162px;
  }
}

.container2{
  @include respond-below(sm) {
    padding: 0;
  }
}

.container {
  // background-color: #000000;
  min-height: 100vh;
  position: relative;
  color: $colors-surface-light;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  *{
    box-sizing: border-box;
    color: white;
  }
}

.circle_wrapper_1 {
  position: absolute;
  left: 60%;
  top: -600px;
}

.circle_1 {
  width: 1500px;
  height: 1500px;
  transform: translateX(-80%) rotate(-30deg);
  flex-shrink: 0;
  // opacity: 0.3;
  position: relative;
  pointer-events: none;
  margin: 0;
}

.circle_wrapper_2 {
  position: absolute;
  right: -800px;
  top: -360px;
}
.circle_2 {
  flex-shrink: 0;
  position: relative;
  pointer-events: none;
}

.circle_wrapper_3 {
  position: absolute;
  right: -30%;
  top: 600px;
}

.circle_3 {
  width: 1500px;
  height: 1500px;
  flex-shrink: 0;
  position: relative;
  pointer-events: none;
  margin: 0;
}



.circle_wrapper_4 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
}
.circle_4 {
  width: 100%;
  height: 700px;
  transform: rotate(10deg);
  flex-shrink: 0;
  position: relative;
  pointer-events: none;
  margin: 0;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.wrapperBlur{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}