@import "../variables.scss";
@import "../mixins.scss";

.container {
  height: 420px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  @include respond-below(md) {
    min-width: 276px;
  }
}

.container_checked .image {
  border:4px solid $colors-brand-color-1;
}

.image {
  border-radius: 60px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.checkbox {
  position: absolute;
  bottom: 24px;
}
