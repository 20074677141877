@import "../variables.scss";
@import "../mixins.scss";

.button {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  background: linear-gradient(129deg, #EE6647 0%, #DF3260 100%);
  box-shadow: 0 6px 32px 0 rgba(223, 50, 96, 0.50), 0 2px 3px 0 rgba(0, 0, 0, 0.12);
  border: 0;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.16px;

  &:hover {
    @include respond-above(md) {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), linear-gradient(129deg, #EE6647 0%, #DF3260 100%);
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.12);
    }
  }

  &[disabled], &_type_secondary {
    background: rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(10px);
    box-shadow: none;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &_type_secondary:hover {
    background: rgba(255, 255, 255, 0.07);
  }

  &_size {
    &_large {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      border-radius: 16px;
      padding: 12px 18px 12px 24px;
      gap: 8px;
    }
  }
}

.icon {
  width: 24px;
  height: auto;
}
