@import "../variables.scss";
@import "../mixins.scss";

.heading {
  text-align: center;
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  color: $colors-white;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 52.8px */
  letter-spacing: -0.44px;
  margin: 0;

  @include respond-below(md) {
    font-size: 24px;
    font-style: normal;
    letter-spacing: -0.24px;
  }

  &_large {
    font-size: 64px;
    font-style: normal;
    letter-spacing: -0.64px;

    @include respond-below(md) {
      font-size: 28px;
      font-style: normal;
      letter-spacing: -0.24px;
    }
  }
}

.subtitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $colors-brand-color-2;

  @include respond-below(md) {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
